<script lang="ts" setup>
import { useRouter } from 'vue-router';

const router = useRouter();
const auth = useAuthStore();
async function handleLogout(){
    await auth.logout();
}

/*function goToSubscription() {
  if (auth.isLoggedIn) {
    router.push('/subscribe');
  } else {
    router.push('/login');
  }
}*/
const isOpen = ref(false);
</script>

<template>
    <div class="">
        <!-- Hamburger Icon -->
        <button data-collapse-toggle="navbar-sticky" @click="isOpen = !isOpen" class="z-50 leading-3 text-7xl md:hidden absolute right-0 top-4 text-dark-red">
            &#9776;
        </button>

        <!-- MOBILE Menu -->
        <div v-show="isOpen" class="md:hidden bg-dark-red absolute h-screen w-full z-50">
            <button data-collapse-toggle="navbar-sticky" @click="isOpen = !isOpen" class=" leading-3 text-7xl md:hidden absolute right-5 top-3 text-white">
                &times;
            </button>
            <ul class="flex flex-col space-y-2 mt-20 text-center text-6xl ">
                <li>
                    <NuxtLink class="" to="/" @click="isOpen = !isOpen">Home</NuxtLink>
                </li>
              <li>
                <NuxtLink class="" to="/calendar-list" @click="isOpen = !isOpen">Calendars</NuxtLink>
              </li>
              <li>
                <NuxtLink class="" to="/contact" @click="isOpen = !isOpen">Contact</NuxtLink>
              </li>
                <li v-if="auth.isLoggedIn && auth.user.role == 'admin' && auth.user.name == 'Shaun'">
                    <NuxtLink class="" to="/calendar-form" @click="isOpen = !isOpen">Create Calendar</NuxtLink>
                </li>
              <li v-if="auth.isLoggedIn && auth.user.role == 'admin' && auth.user.name == 'Shaun'">
                <NuxtLink class="" to="/calendar-list-admin" @click="isOpen = !isOpen">Admin</NuxtLink>
              </li>
                <li v-if="!auth.user">
                  <PatreonButton />
                </li>

                <li v-if="auth.user" @click="handleLogout">
                    <NuxtLink class="" to="/login" @click="isOpen = !isOpen">Logout</NuxtLink>
                </li>


            </ul>
        </div>
    </div>

  <!-- DESKTOP Menu -->
    <nav class="hidden md:block fixed w-full top-0 start-0 z-50 bg-neutral-900">
        <div class="max-w-screen-xl flex flex-wrap items-center justify-items-center justify-between mx-auto p-4">
            <div class="items-center justify-center hidden w-full md:flex md:w-full md:order-1" id="navbar-sticky">
                <ul class="flex flex-col p-4 md:p-0 mt-4 font-medium md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 justify-self-center">
                    <li>
                        <NuxtLink class="" to="/">Home</NuxtLink>
                    </li>
                  <li>
                    <NuxtLink class="" to="/calendar-list">Calendars</NuxtLink>
                  </li>
                  <li v-if="auth.isLoggedIn && auth.user.role == 'admin' && auth.user.name == 'Shaun'">
                    <NuxtLink class="" to="/calendar-form" @click="isOpen = !isOpen">Create Calendar</NuxtLink>
                  </li>
                  <li>
                    <NuxtLink class="" to="/about">About</NuxtLink>
                  </li>
                  <li>
                    <NuxtLink class="" to="/contact">Contact</NuxtLink>
                  </li>
                    <li v-if="!auth.user">
                      <PatreonButton />
                    </li>
                  <li v-if="auth.isLoggedIn && auth.user.role == 'admin' && auth.user.name == 'Shaun'">
                    <NuxtLink class="" to="/calendar-list-admin" @click="isOpen = !isOpen">Admin</NuxtLink>
                  </li>

                    <li v-if="auth.user" @click="handleLogout">
                        <NuxtLink class="" to="/">Logout</NuxtLink>
                    </li>
                  <li>
<!--
                    <button @click="goToSubscription">Subscription</button>
-->
                  </li>
                </ul>
            </div>
        </div>
    </nav>

</template>
