<template>
  <div>
    <button @click="authorizeWithPatreon">Connect with Patreon</button>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { ref, onMounted } from 'vue';
import axios from 'axios';
import {useApiConfig} from "~/composables/useApiConfig";
import {setCSRFToken} from "~/services/calendar.js";


const router = useRouter();
const userStore = useUserStore();
const { apiBaseURL } = useApiConfig();

const authorizeWithPatreon = async () => {
  await setCSRFToken();

  try {
    const { data } = await axios.get(`${apiBaseURL}/patreon/redirect`, { withCredentials: true });
    window.location.href = data.url;
  } catch (error) {
    console.error('Authorization error:', error);
    window.location.href = "https://www.patreon.com/Myra_flr";
  }
};
</script>
