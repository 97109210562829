// stores/user.js
import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
    state: () => ({
        accessToken: null,
        user: null,
        tiers: []
    }),
    actions: {
        setUser(data) {
            this.accessToken = data.accessToken;
            this.user = data.user;
            this.tiers = data.tiers;
            // Save to session storage
            sessionStorage.setItem('accessToken', data.accessToken);
            sessionStorage.setItem('user', JSON.stringify(data.user));
            sessionStorage.setItem('tiers', JSON.stringify(data.tiers));
        },
        loadUserFromSession() {
            const accessToken = sessionStorage.getItem('accessToken');
            const user = sessionStorage.getItem('user');
            const tiers = sessionStorage.getItem('tiers');
            if (accessToken && user && tiers) {
                this.accessToken = accessToken;
                this.user = JSON.parse(user);
                this.tiers = JSON.parse(tiers);
            }
        },
        clearUser() {
            this.accessToken = null;
            this.user = null;
            this.tiers = [];
            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('tiers');
        }
    }
});
